import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { defaultInitialState } from "redux/constant";
import { Status } from "redux/enum";
import { RootState } from "redux/store";
import { UserCoinBalanceParams, UserCoinBalanceState } from "../model/userCoinBalance";
import { ERC20Symbol } from "../enums/ERC20Symbol";

const defaultCoinBalance = {
  ABC: {
    account: '',
    balance: "0",
    type: ERC20Symbol.ABC
  },
  ABCP: {
    account: '',
    balance: "0",
    type: ERC20Symbol.ABCP
  },
}
export const initialState: UserCoinBalanceState = {
  ...defaultInitialState,
  coinBalance: defaultCoinBalance
}

export const fetchAsyncUserCoinBalance = createAsyncThunk(
  "userCoinBalance/fetchAsyncUserCoinBalance",
  async ({tokenType, abcChainAccount}: UserCoinBalanceParams, {rejectWithValue}) => {
    try {
      const response = await MainAPI.get(`/tokens/${tokenType}/balances/${abcChainAccount}`)
      return { ...response.data, type: tokenType }
    } catch (error: any) {
      console.error(error)
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
)

export const userCoinBalanceSlice = createSlice({
  name: 'userCoinBalance',
  initialState,
  reducers: {
    resetUserCoinBalance: (state) => {
      state.coinBalance = defaultCoinBalance
      state.errorMessage = null
      state.status = Status.INITIAL
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncUserCoinBalance.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncUserCoinBalance.fulfilled, (state, { payload }) => {
        state.status = Status.LOADED
        state.coinBalance = { ...state.coinBalance, [payload.type]: payload }
      })
      .addCase(fetchAsyncUserCoinBalance.rejected, (state) => {
        state.status = Status.ERROR
      })
  }
})

export const { resetUserCoinBalance } = userCoinBalanceSlice.actions

export const selectUserCoinBalance = (state: RootState) => state.userCoinBalance

export default userCoinBalanceSlice.reducer