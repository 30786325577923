import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { ErrorResponse } from "models/error";
import { defaultInitialState } from "redux/constant";
import { Status } from "redux/enum";
import { RootState } from "redux/store";
import { WalletUserProfileStatusParams } from "../model/walletUserProfileStatus";
import { WalletUserProfileStatusState } from "../model/walletUserProfileStatusState";

export const initialState: WalletUserProfileStatusState = {
  ...defaultInitialState,
  isEdit: false,
};

export const fetchAsyncWalletUserProfileStatus = createAsyncThunk(
  "walletUserProfileStatus/fetchAsyncWalletUserProfileStatus",
  async (
    { userId, isActive, deactivationReason, supportTicket }: WalletUserProfileStatusParams,
    { rejectWithValue },
  ) => {
    try {
      const response = await MainAPI.patch(`/wallet-profiles/profiles/profile-status/${userId}`, {
        isActive,
        deactivationReason,
        supportTicket,
      });
      return { ...response.data };
    } catch (error: any) {
      console.error(error);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data.message,
      });
    }
  },
);

export const walletUserProfileStatusSlice = createSlice({
  name: "walletUserProfileStatus",
  initialState,
  reducers: {
    resetWalletUserProfileStatus: state => {
      state.errorMessage = null;
      state.status = Status.INITIAL;
    },
    setEditUserProfileStatus: (state, { payload }) => {
      state.isEdit = payload.isEdit;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncWalletUserProfileStatus.pending, state => {
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncWalletUserProfileStatus.fulfilled, (state, { payload }) => {
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncWalletUserProfileStatus.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.errorMessage = (payload as ErrorResponse).data || null;
      });
  },
});

export const { resetWalletUserProfileStatus, setEditUserProfileStatus } = walletUserProfileStatusSlice.actions;

export const selectWalletUserProfileStatus = (state: RootState) => state.walletUserProfileStatus;

export default walletUserProfileStatusSlice.reducer;
