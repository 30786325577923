import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { defaultInitialState } from "../../../redux/constant";
import { Status } from "../../../redux/enum";
import { RootState } from "../../../redux/store";
import { WalletUserLinkedAccount, WalletUserLinkedAccountsState } from "../model/walletUserLinkedAccounts";

export const initialState: WalletUserLinkedAccountsState = {
  ...defaultInitialState,
  walletUserLinkedAccounts: null,
};

export const fetchAsyncWalletUserLinkedAccounts = createAsyncThunk(
  "walletUserLinkedAccounts/fetchWalletUserLinkedAccounts",
  async ({ id }: { id: string | number }, { rejectWithValue }) => {
    try {
      const response = await MainAPI.get(`point-exchange/v2/linked-accounts`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  },
);

export const walletUserLinkedAccountsSlice = createSlice({
  name: "walletUserLinkedAccounts",
  initialState,
  reducers: {
    resetWalletUserLinkedAccounts: state => {
      state.walletUserLinkedAccounts = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncWalletUserLinkedAccounts.pending, state => {
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncWalletUserLinkedAccounts.fulfilled, (state, action) => {
        state.status = Status.LOADED;
        state.walletUserLinkedAccounts = action.payload as [WalletUserLinkedAccount];
      })
      .addCase(fetchAsyncWalletUserLinkedAccounts.rejected, state => {
        state.status = Status.ERROR;
      });
  },
});

export const { resetWalletUserLinkedAccounts } = walletUserLinkedAccountsSlice.actions;

export const selectWalletUserProfile = (state: RootState) => state.walletUserLinkedAccounts;

export default walletUserLinkedAccountsSlice.reducer;
