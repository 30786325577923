import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WalletUserProfile, WalletUserProfileState } from "../model/walletUserProfile";
import { defaultInitialState } from "../../../redux/constant";
import { Status } from "../../../redux/enum";
import { RootState } from "../../../redux/store";
import MainAPI from "../../../apiConfig/MainAPI";
import {
  LoginLinkedAccountStatus,
  WalletUserLoginLinkedAccountStatusState,
} from "../model/walletUserLoginLinkedAccountStatus";

export const initialState: WalletUserLoginLinkedAccountStatusState = {
  ...defaultInitialState,
  walletUserLoginLinkedAccountStatus: null,
};

export const fetchAsyncWalletUserLoginLinkedAccount = createAsyncThunk(
  "walletUserLinkedAccount/fetchAsyncWalletUserLinkedAccount",
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const response = await MainAPI.get(`wallet-profiles/profiles/linked-account/${userId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  },
);

export const walletUserLoginLinkedAccountSlice = createSlice({
  name: "walletUserLinkedAccount",
  initialState,
  reducers: {
    resetWalletUserLoginLinkedAccount: state => {
      state.walletUserLoginLinkedAccountStatus = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncWalletUserLoginLinkedAccount.pending, state => {
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncWalletUserLoginLinkedAccount.fulfilled, (state, action) => {
        state.status = Status.LOADED;
        state.walletUserLoginLinkedAccountStatus = action.payload as LoginLinkedAccountStatus[];
      })
      .addCase(fetchAsyncWalletUserLoginLinkedAccount.rejected, (state, { payload }) => {
        state.status = Status.ERROR;
        state.errorMessage = (payload as { message: string })?.message || null;
      });
  },
});

export const { resetWalletUserLoginLinkedAccount } = walletUserLoginLinkedAccountSlice.actions;

export const selectWalletUserLoginLinkedAccount = (state: RootState) => state.walletUserLoginLinkedAccountStatus;

export default walletUserLoginLinkedAccountSlice.reducer;
